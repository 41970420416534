a {
  text-decoration: none !important;
}

.user-select {
  border: 1px solid #f0f1f5 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-radius: 12px !important;
}
select:required:invalid {
  color: gray;
}
.css-yk16xz-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 10px !important;
  border: 1px solid #f0f1f5 !important;
  border-radius: 12px !important;
}
.css-1pahdxg-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-color: #2684ff !important;
  border-radius: 12px !important;
  box-shadow: 0 0 0 0 #2684ff !important;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}
.text-font-style {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.react-tel-input {
  width: auto !important;
}
.react-tel-input .special-label {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #fff;
  padding: 0 5px;
  white-space: nowrap;
}
.react-tel-input .form-control {
  width: 110px !important;
  font-size: 14px !important;
  border-radius: 9px !important;
  border: 1px solid #f0f1f5 !important;
  padding: 18.5px 9px 18.5px 52px !important;
}
.react-tel-input .country-list {
  width: 250px !important;
}

.fw-bold {
  font-weight: bold;
}
.pointer {
  cursor: pointer;
}
.search-input {
  padding-bottom: 26px !important;
  padding-top: 26px !important;
  font-size: 14px !important;
}
.rounded-border {
  border-radius: 8px;
}
.gap-1 {
  gap: 1rem;
}
.cross-icon {
  top: -6px;
  position: absolute;
  right: -6px;
  z-index: 1;
}
.grid-stories {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(11.6rem, 1fr));
  gap: 1rem;
}
.card-header {
  padding: 0.5rem 0.875rem 0.5rem;
}
.card-body {
  padding: 0.875rem;
}
.image-modal .modal-content {
  background-color: transparent;
  border: none;
}
.image-modal .modal-header .close {
  padding: 0.875rem 1.815rem;
  margin: 0;
  position: absolute;
  right: 12px;
  float: none;
  top: 0px;
  font-size: 40px;
  font-weight: 100;
}
.image-container {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-container img {
  object-fit: cover;
}
img {
  object-fit: cover;
}
.vdo-play-btn {
  position: absolute;
  top: 40px;
  left: 90px;
}
.vdo-play-btn2 {
  position: absolute;
  top: 90px;
  left: 90px;
}
.react-thumbnail-generator video {
  display: none !important;
}
.react-thumbnail-generator canvas {
  width: 300px !important;
  height: 250px !important;
}
.vdo-thumnail canvas {
  width: 200px !important;
  height: 100px !important;
  object-fit: cover;
}
.vdo-thumnail .vdo-play-btn {
  position: absolute;
  top: 35px;
  left: 90px;
  z-index: 1 !important;
}
.overflow-scroll {
  max-height: 600px;
  overflow-y: auto;
}

input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  color: #fff;
  position: relative;
}

input[type="date"]::-webkit-datetime-edit-year-field {
  position: absolute !important;
  border-left: 1px solid #8c8c8c;
  padding: 2px;
  color: #000;
  left: 56px;
}

input[type="date"]::-webkit-datetime-edit-month-field {
  position: absolute !important;
  border-left: 1px solid #8c8c8c;
  padding: 2px;
  color: #000;
  left: 26px;
}

input[type="date"]::-webkit-datetime-edit-day-field {
  position: absolute !important;
  color: #000;
  padding: 2px;
  left: 4px;
}
@media only screen and (max-width: 445px) {
  .vdo-play-btn2 {
    position: absolute;
    top: 90px;
    left: 150px;
  }
}
